<template>
  <div class="form-box source-edit">
    <el-tabs v-model="createParams.type">
      <el-tab-pane label="dxjc" name="include">
        <span slot="label"><i class="iconfont icon-jiance"></i>定向监测</span>
      </el-tab-pane>
      <el-tab-pane name="exclude">
        <span slot="label"><i class="iconfont icon-paichu"></i>定向排除</span>
      </el-tab-pane>
    </el-tabs>
    <div class="ov-h mar-t-10">
      <el-form label-width="130px" :label-position="labelPosition">
        <el-form-item label="监测方案名称">
          <el-input v-model.trim="createParams.name"
            placeholder="请输入方案名称"
            class="w-280"
            maxlength="10"
            show-word-limit>
          </el-input>
        </el-form-item>
        <div class="ov-h">
          <el-select v-model="sourceQuery.source_type"
              @change="handleSourceSearch"
              class="w-120 mar-r-10 lh-60">
            <el-option v-for="(item, index) in searchSelectType"
              :key="index"
              :value="item.value"
              :label="item.label"></el-option>
          </el-select>
          <el-input
            v-loading="searchLoading"
            v-model="sourceQuery.mark"
            :placeholder="placeholderMap[sourceQuery.source_type]"
            class="w-280 pos-r ss-box lh-60"
            @keyup.enter.native="handleSourceSearch">
            <span @click="handleSourceSearch" slot="suffix" class="iconfont icon-sousuo"></span>
          </el-input>
        </div>
        <!-- 内容 -->
        <div class="cont">
          <!-- 搜索结果 -->
          <div v-loading="searchLoading" class="ov-h mar-t-30">
            <!-- 提示 -->
            <div class="empty_cont"
              v-if="!searchResult.data.length">
              <p v-show="searchResult.tips.length > 1" class="t-c red">
                {{ searchResult.tips }}
              </p>
              <div class="button-group" v-show="addTsource.show">
                <el-button type="primary" round @click="toCheck" v-loading="addTsource.loading">添加审核</el-button>
              </div>
            </div>
            <!-- 提示 -->
            <div v-if="searchResult.data.length">
              <div class="title">
                <span class="tit f-l">搜索结果</span>
                <el-button type="text" class="bd-n f-r mar-t-20 mar-r-20" @click="handleClickMultiAdd">
                  <i class="iconfont icon-zengjia"></i>
                  <span style="line-height:18px;">批量添加</span>
                </el-button>
              </div>
              <!-- 搜索结果名单 -->
              <el-table width="100%"
                :data="searchResult.data"
                @select-all="handleCheckAll"
                @select="handleCheckbox"
                class="common_table"
              >
                <el-table-column type="selection" :selectable="checkSelectable" width="55"></el-table-column>
                <el-table-column label="名称">
                  <template slot-scope="scope">
                    <span>{{ getName(scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="信源标识">
                  <template slot-scope="scope">
                    <span>{{ getFeature(scope.row) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="150">
                  <template slot-scope="scope">
                    <el-button type="text"
                      size="small"
                      :disabled="scope.row.isDisabled"
                      @click.native.prevent="handleClickAdd(scope.row)">
                      <i class="iconfont icon-zengjia operate_c"></i><span class="f-l">添加至监测名单</span>
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <pagination :total="searchResult.total"
                :page.sync="sourceQuery.page"
                :size.sync="sourceQuery.size"
                layout="prev, pager, next"
                @pagination="handleSourceSearch">
              </pagination>
            </div>
          </div>
          <!--@end 搜索结果 -->
          <!-- 监测名单 -->
          <div v-if="createParams.content.length" class="ov-h mar-t-30">
            <div class="title">
              <span class="tit b mar-r-10">监测名单</span>
              <p class="f-l mar-l-10">(已添加<span
                class="cor-blue b">{{ createParams.content.length }}</span>个信源，最多<span
                class="cor-blue b">{{ monitorMaxLimit }}</span>个)</p>
              <el-button type="text"
                class="bd-n f-r mar-t-20 mar-r-30"
                @click="handleClickMultiDel">
                <i class="iconfont icon-jianshao"></i>
                <span>批量移除</span>
              </el-button>
            </div>
            <!-- 监测名单 -->
            <el-table width="100%" :data="createParams.content" @selection-change="listenSelectionChange">
              <el-table-column type="selection" width="55"></el-table-column>
              <el-table-column label="名称">
                <template slot-scope="scope">
                  <span>{{ getName(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column label="信源标识">
                <template slot-scope="scope">
                  <span>{{ getFeature(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="category" label="类别"></el-table-column>
              <el-table-column prop="state" label="状态">
                <template slot-scope="scope">
                  <span v-if="scope.row.state === 'succ'">成功</span>
                  <span v-else-if="scope.row.state === 'wait'" class="red">待审核</span>
                  <span v-else-if="scope.row.state === 'fail'" class="red">审核失败</span>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="150" align="center">
                <template slot-scope="scope">
                  <el-button type="text"
                    size="small"
                    @click.native.prevent="handleClickDel(scope.row)">
                    <i class="iconfont icon-jianshao operate_c"></i><span class="f-l">移除</span>
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <!--@end 监测名单 -->
        </div>
        <!--@end 内容 -->
        <div class="button-group mar-t-30">
          <el-button type="primary" round v-loading="loading" :disabled="loading" @click="handleAddSubmit">确定</el-button>
          <el-button round @click="handleAddCancel()">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
  import pagination from '@components/common/pagination';
  import {
    searchSelectType,
    searchResultTableheader,
    monitorTableheader,
    typeMap,
    placeholderMap,
  } from './constants';
  import { sourceFeature, sourceName } from "@/utils/helpers";
  import {
    changeSourcePlan as changePlan,
    sourceSearch,
    createSourcePlan as createPlan
  } from '@/api/plan';
  export default {
    name: 'Edit',
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      // 是不是新增
      isAdd: {
        type: Boolean,
        default: false,
      }
    },
    components: {
      pagination,
    },
    data() {
      return {
        placeholderMap,
        searchSelectType,
        searchResultTableheader,
        monitorTableheader,
        typeMap,
        monitorMaxLimit: 10,
        searchLoading: false,
        labelPosition: 'left',
        checkedAddList: [], // 缓存批量添加的选项
        checkedDelList: [], // 缓存批量移出的选项
        // 信源监测查找参数
        sourceQuery: {
          source_type: searchSelectType[0].value,
          mark: '',
          page: 1,
          size: 5,
        },
        // 信源搜索结果
        searchResult: {
          data: [],
          total: 0,
          tips: '',
        },
        // 提交编辑
        createParams: {
          name: '',
          type: 'include',
          content: [],
        },
        includeContent: [],
        excludeContent: [],
        addTsource: {
          category: '',
          mark: '',
          show: false,
          loading: false
        },
        loading: false
      };
    },
    watch: {
      'createParams.type': function(val) {
        if (val === 'include') {
          this.monitorMaxLimit = 10;
          this.createParams.content = this.includeContent
        } else {
          this.monitorMaxLimit = 50;
          this.createParams.content = this.excludeContent
        }
      },
    },
    created() {
      Object.assign(this.createParams, this.data);
      const { content, type } = this.createParams
      if (type === 'include') {
        this.includeContent = content
      } else if (type === 'exclude') {
        this.excludeContent = content
      }
      // 对数组进行隔离
      if (!this.isAdd) {
        this.createParams.content = [...this.data.content];
      }
    },
    methods: {
      // 超出可添加信源的上限！
      isMaxLimit() {
        return this.checkedAddList.length + this.createParams.content.length > this.monitorMaxLimit;
      },
      // 信源搜索
      handleSourceSearch() {
        if (!this.sourceQuery.mark) return false;
        this.searchLoading = true;
        sourceSearch(this.sourceQuery).then(res => {
          const { state, data, error } = res.data;
          if (state) {
            if (!data.data.length) {
              this.searchResult.tips = `未搜索到${this.sourceQuery.mark}!`;
            }
            // 点击搜索之后，需要判断出来那些是已经添加的，这里通过 domain 判断，下同
            // 提取出来已经缓存列表的 domain
            const domainList = this.filterDomain(this.createParams.content);
            data.data.map(item => {
              if (domainList.indexOf(sourceFeature(item)) > -1) {
                item.isDisabled = true;
              } else {
                item.isDisabled = false;
              }
            });
            this.searchResult.data = data.data;
            this.sourceQuery.page = Number(data.page);
            this.searchResult.total = Number(data.total);
            if (_.size(this.searchResult.data) >= 1) {
              this.addTsource.show = false
            } else if (_.size(this.searchResult.data) < 1) {
              this.addTsource.category = this.sourceQuery.source_type
              this.addTsource.mark = this.sourceQuery.mark
              this.addTsource.show = true
            }
          } else {
            this.$message.error(error || '未知错误，请重试！');
          }
          this.searchLoading = false;
        });
      },
      // 搜索结果是否可以选择
      checkSelectable(row, index) {
        return !row.isDisabled;
      },
      toCheck() {
        const { category, mark } = this.addTsource
        this.addTsource.loading = true
        let row = {category: category, state: "wait"}
        switch(category) {
          case "微博":
            row['userid'] = mark
            row['author'] = mark
            break;
          case "微信":
            row['author'] = mark
            row['username'] = mark
            break;
          case "网站":
            row['host'] = mark
            row['source_name'] = mark
            break;
          case "评论":
            row['host'] = mark
            row['source_name'] = mark
            break;
          case "推特":
            row['author'] = mark
            row['userid'] = mark
            row['username'] = mark
            break;
          case "脸书":
            row["author"] = mark
            row["username"] = mark
            break;
          case "论坛":
            row['host'] = mark
            break;
        }

        const domainList = this.filterDomain(this.createParams.content);
        if (domainList.indexOf(sourceFeature(row)) === -1) this.createParams.content.push(row);
        this.addTsource.show = false
        this.addTsource.loading = false
      },
      // 特征提取 已经存在的 domain 用于匹配删除
      filterDomain(arr) {
        const domainList = [];
        arr.map(item => {
          domainList.push(sourceFeature(item))
        });
        return domainList;
      },
      getFeature(item) {
        return sourceFeature(item)
      },
      getName(item) {
        return sourceName(item)
      },
      // 批量添加
      handleClickMultiAdd() {
        if (!this.checkedAddList.length) return false;
        if (this.isMaxLimit()) {
          this.$message.error('超出可添加信源的上限！');
        } else {
          this.checkedAddList.map(item => {
            // 如果为 true 则说明已经被添加了 不能重复添加
            if (!item.isDisabled) {
              item.isDisabled = !item.isDisabled;
              this.createParams.content.push(item);
            }
          });
          this.checkedAddList = [];
        }
      },
      // 批量移出
      handleClickMultiDel() {
        if (!this.checkedDelList.length) return false;
        const { content } = this.createParams;
        // 提取出来 删除缓存列表的 domain
        const domainList = this.filterDomain(this.checkedDelList);
        if (_.size(this.createParams.content) === _.size(domainList)) {
          this.$message.warning("监测名单至少需要一个信源数")
          return false
        }
        this.createParams.content = _.filter(content, (item) => {
          return domainList.indexOf(sourceFeature(item)) === -1
        })
      },
      // 点击添加到监测名单
      handleClickAdd(row) {
        const { content } = this.createParams;
        if (content.length >= this.monitorMaxLimit) {
          this.$message.error('超出可添加信源的上限！');
        } else {
          // 设定类别
          row.source_type = this.sourceQuery.source_type;
          row.isDisabled = true;
          this.createParams.content.push(row);
        }
      },
      // 点击移出监测名单
      handleClickDel(row) {
        const { content } = this.createParams;
        for (let i = 0; i < content.length; i++) {
          if (content[i].category === row.category) {
            if (sourceFeature(content[i]) === sourceFeature(row)) {
              row.isDisabled = false;
              content.splice(i, 1);
              break;
            }
          }
        }
        if (_.size(content) < 1) {
          this.$message.warning("监测名单至少需要一个信源数")
        }
      },
      // 搜索结果 全选
      handleCheckAll(selection) {
        selection.map(item => {
          item.source_type = this.sourceQuery.source_type;
        });
        this.checkedAddList = selection;
      },
      // 搜索结果 点选
      handleCheckbox(selection, row) {
        // 设定类别
        row.source_type = this.sourceQuery.source_type;
        this.checkedAddList.push(row);
      },
      // 监测名单 全选
      listenSelectionChange(selection) {
        this.checkedDelList = selection;
      },
      // 监测名单 点选
      handleMonitorCheckbox(selection, row) {
        this.checkedDelList.push(row);
      },
      // 新建提交
      handleAddSubmit() {
        const { name, content } = this.createParams;
        if (!name) {
          this.$message.error('请填写监测方案名称！');
          return false;
        } else if (!content.length) {
          this.$message.error('请选择监测名单！');
        } else {
          this.handleCreateSource(this.createParams);
        }
      },
      // 编辑保存
      handleSubmitEdit(obj) {
        this.loading = true
        changePlan(obj).then(res => {
          const { state, error, message } = res.data;
          if (state) {
            this.$message.success('编辑成功');
            Object.assign(this.data, obj);
            this.$emit('closeEdit');
          } else {
            this.$message.error(error);
          }
          this.loading = false
        });
      },
      // 新增保存
      handleSubmitAdd(obj) {
        this.loading = true
        createPlan(obj).then(res => {
          const { state, error, message } = res.data;
          if (state) {
            this.$message.success('保存成功');
            this.$emit('closeAdd', true);
          } else {
            this.$message.error(error);
          }
          this.loading = false
        });
      },
      // 保存定向信源
      handleCreateSource() {
        const obj = JSON.parse(JSON.stringify(this.createParams));
        if (this.isAdd) {
          this.handleSubmitAdd(obj);
        } else {
          this.handleSubmitEdit(obj);
        }
      },
      // 编辑取消
      handleAddCancel() {
        if (this.isAdd) {
          this.$emit('closeAdd');
        } else {
          this.$emit('closeEdit');
        }
      },
    },
  };
</script>
<style scoped>
.form-box .icontfont{margin-right: 5px;}
.form-box >>> .el-tabs__header{margin: 0;}
.form-box >>> .el-tabs__header .iconfont{font-size: 20px;}
.form-box .title{overflow: hidden;border-bottom: 1px solid rgba(124,124,124,.2);}
.form-box >>> .el-pagination{text-align: center;margin-top: 30px;}
.form-box >>> .el-pagination button:disabled,.form-box >>> .el-pager li{background:none;}
.form-box >>> .el-pager li{line-height:28px;width: auto;height: 28px;}
.form-box .title p span,.form-box >>> .el-button span{float:none;}
.form-box >>> .el-button--text{padding: 0;}
.form-box .iconfont{margin-right: 5px;}
.form-box >>> .el-table td,.form-box >>> .el-table th,.form-box >>> .el-table tr, .el-table::before,.el-table{background: none;border:none;}
.form-box >>>.el-table th>.cell{font-size: 14px;color: #333;}
.bd{border-bottom: 1px solid #f2f2f2;}
.form-box >>> .el-button--text{color: #606266;}
.form-box .lh-60{line-height: 60px;}
</style>
