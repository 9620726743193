<template>
  <div class="content source-plan">
    <div class="w-100">
      <el-card class="box-card">
        <div class="mod-title">定向信源信息列表</div>
        <el-row class="empty hide">
          <div>
            <img
              src="@/assets/images/common/empty/dxxygl.svg"
              alt="定向信源管理"
              width="400"
              class="img"
              type="image/svg+xml"
            />
            <p>
              <span>空空的，快来设置定向信源方案吧</span>
            </p>
          </div>
        </el-row>
        <div v-loading="listLoading">
          <div class="mod-list min-h-600 lh-60">
            <ul v-if="tableData.length >= 1">
              <li>
                <h3>
                  <span class="num-tit">序号</span>
                  <span class="mod-list-title2">方案名称</span>
                  <span class="tag">类别</span>
                  <span class="mod-list-title1">创建日期</span>
                  <span class="mod-list-title1">创建者</span>
                  <span class="tag">编辑</span>
                  <span class="tag">删除</span>
                </h3>
              </li>
              <li v-for="(item, index) in tableData" :key="item.id">
                <div>
                  <span class="num bg-555" v-if="index == 0">1</span>
                  <span class="num bg-689" v-else-if="index == 1">2</span>
                  <span class="num bg-8ea" v-else-if="index == 2">3</span>
                  <span class="num" v-else-if="index >= 3">{{
                    index + 1
                  }}</span>
                  <span class="mod-list-title2">{{ item.name }}</span>
                  <span class="tag">{{ typeMap[item.type] }}</span>
                  <span class="mod-list-title1">{{ item.created }}</span>
                  <span class="mod-list-title1">{{ item.createdby_name }}</span>
                  <i
                    :class="['iconfont', 'icon-bianji', item.edit ? 'djh' : '']"
                    @click="handleEdit(item)"
                  ></i>
                  <span class="tag">
                    <i
                      class="iconfont icon-shanchu"
                      @click="handleClickDel(item)"
                    ></i>
                  </span>
                </div>
                <!-- 编辑盒子 -->
                <!-- <div class="dxxygl_bg" > -->
                <!-- 定向监测与定向排除 -->
                <edit
                  :data="item"
                  v-if="item.edit"
                  @closeEdit="handleCloseEdit(item)"
                ></edit>
              </li>
            </ul>
            <!-- 新增 -->
            <div>
              <el-button
                plain
                class="bd-n mar-t-10"
                icon="iconfont icon-zengjia"
                @click="handleAddPlan"
                >新增定向信源方案</el-button
              >
              <edit
                :isAdd="true"
                @closeAdd="handleCloseAdd"
                v-if="isShowAddForm"
              ></edit>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <confirm
      class="single-information"
      ref="confirmDel"
      title="删除方案"
      @must="del"
    />
  </div>
</template>
<script>
import confirm from '@components/common/confirm';
import Edit from './edit.vue';
import { typeMap } from './constants';
import { getSourceList, delSourcePlan as delPlan } from '@/api/plan';
const _ = window._;
export default {
  components: {
    Edit,
    confirm
  },
  data() {
    return {
      typeMap,
      labelPosition: 'left',
      listLoading: false,
      searchLoading: false,
      isShowAddForm: false, // 是否显示打开新增信源框
      tableData: [],
      listQuery: {
        page: 1,
        size: 10
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    handleClick(tab, event) {},
    // 拉取数据列表
    loadData() {
      this.listLoading = true;
      getSourceList(this.listQuery).then(res => {
        const { state, error, data } = res.data;
        if (state) {
          data.map(item => {
            item.content =
              typeof item.content === 'string'
                ? JSON.parse(item.content)
                : item.content;
            // 增加一个字段用户控制编辑
            item.edit = false;
          });
          this.isShowAddForm = data.length ? false : true;
          this.tableData = data;
        }
        this.listLoading = false;
      });
    },
    // 编辑定向信源
    handleEdit(row) {
      row.edit = !row.edit;
    },
    handleCloseEdit(row) {
      // this.handleEdit(row);
      // this.loadData();
      row.edit = false;
    },
    handleCloseAdd(isLoad = false) {
      isLoad ? this.loadData() : this.handleAddPlan();
    },
    // 新增信源方案
    handleAddPlan() {
      if (_.size(this.tableData) >= 10) {
        this.$message.warning('定向信源最多只能添加10组');
        return false;
      }

      this.isShowAddForm = !this.isShowAddForm;
    },
    // 删除
    handleClickDel(item) {
      const textBox = [{ type: 'error', text: '您将不能再查看此方案' }];
      this.$refs.confirmDel.show(textBox, { id: item.id });
    },
    del(data) {
      delPlan(data).then(res => {
        const { state, message, error } = res.data;
        if (state) {
          this.$message.success('删除成功');
          this.loadData();
        } else {
          this.$message.error(error || '删除失败，请重试！');
        }
      });
    }
  }
};
</script>
<style scoped>
.source-plan .mod-title {
  margin-left: 0;
}
.source-plan >>> .el-card__body {
  padding: 30px 40px;
}
.source-plan .mod-list-title2 {
  width: 22%;
}
.source-plan .mod-list-title1 {
  width: 18%;
}
.source-plan span.tag:nth-child(3) {
  width: 16%;
}
.source-plan span.tag:nth-child(6),
.source-plan span.tag:nth-child(7) {
  width: 10%;
}
.source-plan li {
  height: auto;
}
.source-plan li:not(:first-child) span:nth-child(7) {
  float: right;
}
.source-plan .icon-bianji {
  margin-left: 4.5%;
}
</style>
